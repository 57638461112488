import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { title: "BASF Mi Cuenta - Login" },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue")
  },
  {
    path: "/",
    name: "landing",
    meta: { title: "BASF Mi Cuenta" },
    component: () => import("../views/Landing-new.vue")
  },
  {
    path: "/landing/landing-new",
    name: "landing-new",
    meta: { title: "BASF Mi Cuenta" },
    component: () => import("../views/Landing.vue")
  },
  {
    path: "/permisos",
    name: "permisos",
    meta: { title: "BASF Mi Cuenta - Permisos" },
    component: () => import("../views/Permisos.vue")
  },
  {
    path: "/password/:url",
    name: "password",
    meta: { title: "BASF Mi Cuenta - Password" },
    component: () =>
      import(/* webpackChunkName: "password" */ "../views/Password.vue")
  },
  {
    path: "/excel",
    name: "excel",
    meta: { title: "BASF Mi Cuenta - Excel" },
    component: () => import("../views/Excel.vue")
  },
  {
    path: "/metricas",
    name: "metricas",
    meta: { title: "BASF Mi Cuenta - Métricas" },
    component: () => import("../views/Metricas.vue")
  },
  {
    path: "/seleccionarCuenta",
    name: "seleccionarCuenta",
    meta: { title: "BASF Mi Cuenta - Seleccionar Cuenta" },
    component: () => import("../views/SeleccionarCuenta.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
