import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./store";

Vue.directive("infocus", {
  isLiteral: true,
  inserted: (el, binding) => {
    let f = () => {
      let rect = el.getBoundingClientRect();
      let inView =
        rect.width > 0 &&
        rect.height > 0 &&
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight + 200 || document.documentElement.clientHeight);
      if (inView) {
        el.classList.add(binding.value);
        setTimeout(() => {
          el.classList.remove(
            "showElement",
            "hidden",
            "hidden-down",
            "hidden-right",
            "hidden-left"
          );
        }, 800);
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
    f();
  }
});

if (process.env.VUE_APP_MODE == "prod") {
  Vue.prototype.$localurl = process.env.VUE_APP_URL;
  Vue.prototype.$cyo_url = process.env.VUE_APP_CYO_URL;
  Vue.prototype.$pog_url = process.env.VUE_APP_POG_URL;
  Vue.prototype.$mialta_url = process.env.VUE_APP_MIALTA_URL;
  Vue.prototype.$mispedidos_url = process.env.VUE_APP_MISPEDIDOS_URL;
  Vue.prototype.$dev = false;
} else if (process.env.VUE_APP_MODE == "dev") {
  Vue.prototype.$localurl = process.env.VUE_APP_URL_DEV;
  Vue.prototype.$cyo_url = process.env.VUE_APP_CYO_URL_DEV;
  Vue.prototype.$pog_url = process.env.VUE_APP_POG_URL_DEV;
  Vue.prototype.$mialta_url = process.env.VUE_APP_MIALTA_URL_DEV;
  Vue.prototype.$mispedidos_url = process.env.VUE_APP_MISPEDIDOS_URL_DEV;
  Vue.prototype.$dev = true;
} else {
  Vue.prototype.$localurl = process.env.VUE_APP_URL_LOCAL;
  Vue.prototype.$pog_url = process.env.VUE_APP_POG_URL_LOCAL;
  Vue.prototype.$dev = true;
}

Vue.config.productionTip = false;

Vue.prototype.$eventHub = new Vue(); // Global event bus

axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
